import React from "react";
import { Link, useNavigate } from "react-router-dom";
import JoinUs from "./joinus";
import { Icon } from "@iconify/react";
import { useDispatch } from "react-redux";
import { useWallet } from "@aptos-labs/wallet-adapter-react";
import { toggleSidebar, toggleWalletPanel, toggleMintDialog } from "../../state/dialog";

interface Props {
  className?: string;
  active?: number;
}

const Header: React.FC<Props> = ({ className, active }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { connected, account } = useWallet();

  const handleMint = () => {
    dispatch(toggleMintDialog(true));
  };

  return (
    <div
      className={`${className} flex w-full h-[87px] md:h-[130px] md:pt-5 md:px-20 md:pb-5`}
    >
      <div
        className={`flex w-full h-full bg-primary pt-6 pb-4 md:pt-0 md:pb-0 justify-between items-center px-4 md:px-10 rounded-t md:rounded-t-[10px] border-2 rounded-[10px] border-gray-dark shadow-default`}
      >
        <div className="flex items-center">
          <img
            onClick={() => navigate("/")}
            src="/assets/logo.svg"
            className="h-auto cursor-pointer w-[94px] md:w-[100px]"
          />
        </div>
        <div className="flex items-center gap-3">
          <div className="items-center justify-center hidden gap-4 md:flex">
            <a href={"https://mint.coolsloths.com/"} rel="noreferrer" target="_blank" className="flex items-center px-3 py-2 bg-[#6BCDCB] border rounded justify-centerh-full border-gray-dark shadow-default">
              <p className="cursor-pointer text-[#FFF] text-lg font-bold leading-normal uppercase">Mint Now!</p>
            </a>
            <div onClick={handleMint} className="flex items-center h-full px-3 py-2 mr-8 bg-white border rounded justify-cente border-gray-dark shadow-default">
              <span className="cursor-pointer text-[#222] text-lg font-bold leading-normal uppercase">CoolList eligibility</span>
            </div>
            <div className="flex">
              <JoinUs />
            </div>
          </div>
          <div className="z-20 block md:hidden">
            <Icon
              onClick={() => {
                dispatch(toggleSidebar(1));
              }}
              icon={"grommet-icons:menu"}
              className="text-[32px] cursor-pointer text-black"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
